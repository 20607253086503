/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

const transitionDelay = 100
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    window.scrollTo(...currentPosition)
  }, transitionDelay)
  return false
}
